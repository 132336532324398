.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family:'La Belle Aurore';
}

.bottom-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.contact-page{
    .text-zone{
        position:absolute;
        left: 10%;
        top: 50%;
        transform:translateY(-50%);
        width: 45%;
        vertical-align: middle;
        display: table-cell;
        max-height: 80%;
    }
    h1{
        font-size: 53px;
        font-family:'Ink Free';
        color:#ccd6f6;
        font-weight: 700;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        top:0;
        left: 0%;
        animation: fadeInLeft 1s 1.8s backwards;
    }
    .left-side {
        color:#3ab396;
        font-weight: 700;
        font-size: 21px;
        font-family: 'Ink Free';
        animation: fadeIn 1s 1.8s backwards;
        display:inline;
        text-align: left;
        width:100px; 
        margin:10px auto;
        left: 0%;
        padding:0;
        position:relative;
        &:nth-of-type(1) {
            animation-delay: 1.1s;
        }
        &:nth-of-type(2) {
            animation-delay: 1.2s;
        }
        &:nth-of-type(3) {
            animation-delay: 1.3s;
        }
    }
}